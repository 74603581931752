@import '../../variables';

input[type=text], select, textarea,
input[type=email],
input[type=number] {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}
 

#loaderWrapper,
#SuccesWrapper {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: #f0f8ff;
  display: none;
}

.success {
  color: green;
  font-size: 1vw;
}

.errorAwesomeIcon {
  font-size: 0.9vw;
  padding-right: 10px;
  padding-left: 5px;
}

.errMsg {
  padding:  2px;
  margin: 4px 0;
  font-size: 0.9vw;
  color: #ce0000;
  background-color: #ff000014;
  border-radius: 4px;
  width: 100%;
  letter-spacing: 1px;
  font-style: italic;
}

.showHideLoader {
  display: flex;
  opacity: 1;
  transition: all 0.35s;
}

.errMsgAligner {
  padding: 5px 0 ;
  text-align: left;
  min-height: 1px;
}

.sendOnotherMsgBtn {
  background-color: #2b9b1f;
  border: 1px solid green;
  padding: 12px 7px;
  border-radius: 7px;
  cursor: pointer;
  color: #fff;
  font-weight: bold;
}

.sendOnotherMsgAwasomeIcon {
  padding-left: 5px;
}

.successMsgAwesomeIcon {
  padding-right: 8px;
  transform: rotate(15deg);
}

  /* To center the spinner*/
.pos-center {
  position: fixed;
  top: calc(50% - 40px);
  left: calc(50% - 40px);
}
  
.loader{
  border: 7px solid #9eddff;
  border-top: 7px solid #3498db;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

.formFullNameWrapper {
  display: flex;
}

.formFullNameWrapper > input,
.formFullNameWrapper > textarea {
  flex: 1;
  margin: 0;
  border: none !important;
  border-right: 1px solid #ccc !important;
  border-radius: 0;
}

.formFullNameWrapper > textarea {
  min-height: 120px;
}

.formFullNameWrapper > input::placeholder,
.formFullNameWrapper > textarea::placeholder  {
  color: #ccc;
}

.ContactFormSubmitBtn {
  background-color: #2b9b1f ;
  font-weight: bold;
  width: 100%;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #217518 ;
  }
}

//Animation
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
  
.NameSectionWrapper {
  border: 1px solid #ccc;
  margin-bottom: 10px;
}

.NameLabel {
  padding: 6px 12px;
  color: #888;
  background-color: #fff;
  text-align: left;
  border-bottom: 1px solid #ccc;
}

.faEnvelopeSendMsg {
  margin-right: 10px;
  transform: rotate(1deg);
}

.emailFormWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.emailForm {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.messageWraper {
  flex: 1;
  display: flex;
  flex-direction: column;

  .textAreaWrapper , textarea {
    height: 100%;
  }
}

  @include forSmallDesktop {
    .errMsg,
    .success,
    .errorAwesomeIcon{
      font-size: 1.2vw;
    }
  }
  
@include for-tablet-design {
  .recaptchaClass {
    margin: 0 auto;    
  }

  .errMsg,
  .success,
  .errorAwesomeIcon{
    font-size: 1.7vw;
  }
}
  
@include for-mobile-design {
  .errMsg,
  .success,
  .errorAwesomeIcon{
    font-size: 3vw;
  }
}

.grecaptcha-badge {
    margin: 10px;
}

.grecaptcha-badge {
  margin-left: 0;
  margin-right: 0;
}