@import '../../variables';

.Footer {
  background-color: rgb(25, 29, 41);
  border-radius: 5px 5px 0 0;
  min-height: 35vh;
  padding: 0 $base-padding;
  color: #fff;
  display: flex;
  flex-direction: row;
}

.footerImage {
  z-index: 1;
  flex: 1;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 15px;
  margin-right: 0;
  margin-top: 4%;
  position: relative; 
}

.footerNavBar {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 4%;
  
  a {
      color : #fff;
      display: block;
      text-align: left;
      cursor: pointer;
      margin-top: 15px;
      font-size: 1vw;
  }
}

.footerContacts {
  visibility: hidden;
  flex: 1;
  border-top: 50px solid #1f2330;
  background-color: #1f2330;
  padding: 5px;
  margin: 0 40px;
  margin-top: -50px;
  margin-left:0;
  border-top-left-radius: 15%;
  border-top-right-radius: 2%; 
  font-size: 1vw;
  padding: 0 20px 10px 20px;
  text-align: left;
}

.FooterSlogan {
  margin-top: 0 ;
  font-size: 1.6vw;
  text-shadow: 2px 2px 2px rgb(31, 154, 0);
}

.animation {
  animation: 0.75s ease-out 0s 1 getFooter;
  visibility: visible;
}

@keyframes getFooter {
    0% {
      opacity: 0;
      transform: translateX(-100%)
 
    }
    100% {
      opacity: 1;
      transform: none;
      
    }
  }

@keyframes windmillRotation {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  
  100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}

.WindmillBase {
  position: absolute;
  top: 47%;
  height: 150px;
  left: 34%;
  z-index: 9999;
  -webkit-transform: rotateY(69deg);
  -moz-transform: rotateY(69deg);
  -ms-transform: rotateY(69deg);
  -o-transform: rotateY(69deg);
  transform: rotateY(69deg);
  z-index: 0;
}

#div1 {
  position: relative;
  height: 150px;
  width: 150px;
}

#div2 {
  animation: windmillRotation 8s infinite linear ;
  background-size: cover;
  position: absolute;
  width: 150px;
  height: 150px;
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  transform: rotate(360deg);
  z-index: 0;
}

.ActiveWindmillWrapper {
  z-index: 0;
  bottom: 75px;
  position: absolute;
  -webkit-transform: rotateY(25deg);
  -moz-transform: rotateY(25deg);
  -ms-transform: rotateY(25deg);
  -o-transform: rotateY(25deg);
  transform: rotateY(25deg);
}

.windmillSpeed2 {
  animation: none !important;
  animation: windmillRotation 6s infinite linear !important;
}

.windmillSpeed3 {
  animation: none !important;
  animation: windmillRotation 5.5s infinite linear !important;
}

.footeMenuFontAwesome {
  margin-right: 5px;
}

.center {
  margin: 5% auto;
}

.footerNavBarHeader {
text-align: left;
margin: 0 10px 10px 3%;
font-size: 1.3vw;
}

.footerLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px dashed #ffffff14;
  padding-bottom: 15px;

  .CompanyName {
    font-size: 1.2vw;
  }
}

.Footer .ContactsWrapper {

  > div {
    flex: 1 90%;
    padding-bottom: 15px;
    margin: 2%;
  }
}

 .footerContactSlotImg {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 90%;
    padding: 10px 5% 0 10px;
 }

 .footerContactSlotText{
  white-space: pre-line;

  & > p {
    font-size: 0.9vw;
    margin:  0;
  }
 }

.footerContactSlotHeader {
  margin:  0;
}

.footeMenuFontAwesome {
  color: #a9d400;
}

@include forSmallDesktop {

  .footerLogo .CompanyName {
    font-size: 1.6vw;
  }

  .Footer {
    padding: 0 $small-display-padding !important;
  }

  .footerNavBar {
    a {

        font-size: 1.2vw;
    }
  }

  .footerContactSlotText{
    & > p {
      font-size: 1.2vw;
    }
  }

  .footerContactSlotHeader{
    font-size: 1.1vw !important;
  }
}

@include for-tablet-design {
  .footerLogo .CompanyName {
    font-size: 1.8vw;
  }

  .footerNavBar {
    a {
        font-size: 1.6vw;
    }
  }

  .center {
    margin: 0 auto;

    a {
      margin-top: 10px;
    }
  }

  .footerNavBarHeader {
    text-align: left;
    margin: 10px 20% ;
    font-size: 1.4vw;
  }

  .Footer {
    padding: 0 !important;
  }

  .Footer {
    flex-direction: column;
  }

  .footerContactSlotText,
  .footerContactSlotHeader{

    & > p {
      font-size: 1.6vw;
      margin:  0;
    }
  }

  .footerContactSlotHeader{
    font-size: 1.6vw !important;
  }

  .footerContacts  {
    margin: 10px 0;
    border: none;
    background-color: #1f2330;
    padding: 5% 0 2.5% 0;
  }

  .ContactsWrapper {
    padding-left: 0;
  }

  .footerImage {
    min-height: 150px;
    margin-left:  -3% ;
    margin-top: 3%;
    margin-bottom: 0;
    background-size: cover;
  }

  .FooterSlogan {
    display: none;
  }
}

@include for-mobile-design {

  .footerLogo .CompanyName {
    font-size: 2.5vw;
  }

  .Footer {
    padding: 0 $base-padding-mobile !important;
  }

  .center {
    margin: 0 auto;

    a {
      font-size: 2.5vw;
    }
   }

  .footerNavBarHeader {
    font-size: 2.5vw;
  }

  .footerContactSlotText{
    & > p {
      font-size: 2.5vw !important;

    }
  }

  .footerContactSlotHeader{
    font-size: 2.5vw !important;
  }
}

.Footer .ContactsWrapper {
  padding: 0;
}