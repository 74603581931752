@import '../../variables';

.solarWorksSectionWrapper {
    overflow-x: hidden;
    border-radius: 5px;
    min-height: 70vh;
    background-color: #fff;
    display: flex;
    padding: 2.5% $base-padding 2.5% $base-padding;
    text-align: left;
}

.solarPowerWorks {
    flex: 1;
    padding: 20px 20px 0 0 ;
}

.solarPowerWorksImg {
    background-size: cover;
    background-repeat: no-repeat;
    flex: 1;
    border-radius: 7px;
    margin: 30px 20px 60px 0 ;
    box-shadow: 0 0 10px #000;
}

.worksWeDoContainer {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 3px dotted #ccc;
    margin: 5% 0;
}

#solarWorksWeDoContainer {
    visibility: hidden;
}

.SolarPowerWorksHeader {
    font-size: 1.3vw;
    color: #191d29;
    text-shadow: 0.5px 0.5px rgb(0 153 154);
    text-align: center;
    letter-spacing: 0.5px;
    margin: 10px 0 ;
    
}

.solarWorksTransit {
    animation: 1s ease-out 0s 1 SolarWorksWeDoTransit;
    visibility:visible !important;
}

@include forSmallDesktop {
    .solarWorksSectionWrapper {
        padding: 2.5% $small-display-padding ;
    }

    .solarPowerWorksImg {
        margin: 18% 20px 18% 0;
    }

    .SolarPowerWorksHeader {
        font-size: 1.4vw;
    }
}

@include for-tablet-design {
    .SolarPowerWorksHeader {
        font-size: 1.8vw;
    }

    .solarWorksSectionWrapper {
        padding: 2.5%  $base-padding-tablet !important;;
        flex-direction: column-reverse;
        min-height: 1vh;
        padding: 0;
   }

   
    .solarPowerWorks {
        padding-right: 0;
    }

    .worksWeDoContainer {
        justify-content: center;
    }

    .solarPowerWorksImg {
        min-height: 50vh;
        margin: 8% 20px 8% 20px;
    }
}
  

@include for-mobile-design{
    .SolarPowerWorksHeader {
        font-size: 3vw;
    }

    .solarPowerWorksImg {
        min-height: 35vh;
        margin: 8% ;
    }

    .solarWorksSectionWrapper {
        padding: 2.5% $base-padding-mobile !important;
   }
}

@keyframes SolarWorksWeDoTransit {
    0% {
     
      transform: translateX(-100%);
    }

    100% {
       
      transform: translateX(0);
    }
}

.imgLazyLoaderSolarMainImg {
    border: 7px solid #9eddff;
    border-top: 7px solid #3498db;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spinLoader 1s linear infinite;
    margin: 0 auto;
    margin: 10px auto;
    flex: none;
    overflow: hidden;
}

.flexItem {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}