@import 'variables';

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.overflowHidden {
  overflow: hidden;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

.App {
  text-align: center;
  background-color: #fff;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  min-height: 100vh;
  position: relative;
}

* {
  word-break: break-word;
}

.BodyWrapper {
  min-height: 100vh;
}
 
@media only screen and (max-width: 800px) {
  .BodyWrapper {
    margin: 0;
  }
}

@include for-mobile-design{
  .BodyWrapper {
    margin: 0;
  }
}