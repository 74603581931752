@import '../../variables';

.electritionWorksWrapper {
  min-height: 70vh;
  background-color: rgb(25, 29, 41);
  display: flex;
  padding: 2.5% $base-padding 0 $base-padding;
  text-align: left;
  color: #fff; 
}

.electritionWorksImg{
  margin: -5.5% 35px 40px -4.5%;
  background-size: cover;
  background-repeat: no-repeat;
  flex: 1;
  border-radius: 7px;
  box-shadow: 0 0 10px #000;
}

.electricPowerWorks {
  overflow-x: hidden;
  flex: 1;
  padding: 20px;
  padding-right: 0;
}

.electricPowerWorksHeader {
  font-size: 1.3vw;
  color: #d6d6d6;
  text-shadow: 0.5px 0.5px rgb(0 153 154);
  text-align: center;
  letter-spacing: 0.5px;
  margin: 10px 0 ;
}

.electroWorksTransit {
  animation: 1s ease-out 0s 1 ElectroWorksWeDoTransit;
  visibility:visible !important;
}

.electricPowerWorks{
  .worksWeDoContainer {
    visibility: hidden;

    .worksWeDoWrapper {
      animation: none;
      background-color: rgb(226 233 255);
      color: rgb(25, 29, 41);
      margin-left: 3% ;
      margin-right: 0 ;
      width: 29.5%;

      .worksWeDoLogoWrapper {
        .worksWeDoWrapperLogo{
          padding: 0;    
        }
      }
    }
  }
}

@keyframes ElectroWorksWeDoTransit{
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

@include forSmallDesktop {
  .electritionWorksWrapper {
    padding: 2.5% $small-display-padding    ;
  }

  .electricPowerWorks .worksWeDoContainer .worksWeDoWrapper {
    width: 42.5%; 
    margin: 3% 3% 3% 0;
  }

  .electritionWorksImg {
    background-position: center center;
    margin: 11% 20px 11% 0;
  }

  .electricPowerWorksHeader {
    font-size: 1.6vw;
  }
}

@include for-tablet-design {
  .electricPowerWorks {
    padding: 2.5% $base-padding-tablet 0 $base-padding-tablet  !important;
  }

  .electritionWorksImg {
    min-height: 50vh;
    margin: 0 ;
    border-radius: 0;
}

  .electricPowerWorks .worksWeDoContainer .worksWeDoWrapper {
    width: 28.5%; 
  }

  .electricPowerWorksHeader {
    font-size: 1.8vw;
  }

  .electritionWorksWrapper {
    overflow-x: hidden;
    min-height: 1vh;
    flex-direction: column;
    padding: 0;
  }

  .electricPowerWorks {
    padding: 0 5%;
  }
}

@include for-mobile-design {
  .electricPowerWorksHeader {
    font-size: 3vw;
    margin: 5% 0;
  }

  .electricPowerWorks {
    padding: 2.5% $base-padding-mobile 0 $base-padding-mobile !important;
  }

  .electritionWorksImg{
    min-height: 35vh;
  }
}

.imgLazyLoaderElectritionMainImg {
  border: 7px solid #9eddff;
  border-top: 7px solid #3498db;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spinLoader 1s linear infinite;
  margin: 0 auto;
  margin: 10px auto;
  flex: none;
  overflow: hidden;
}