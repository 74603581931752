@import '../../variables';

.MapSection {
    min-height: 60vh;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: 4% $base-padding 3% $base-padding;
    text-align: left;
    border-radius: 5px;
}


.mapSectionHeader {
    font-size: 1.3vw;
    color: #191d29;
    text-shadow: 0.5px 0.5px rgb(0 153 154);
    text-align: center;
    letter-spacing: 0.5px;
    margin: 0 0 3% 0;
    text-decoration: underline;
}


//PROGRESS BAR
.clientCount {
    white-space: pre-line;
    font-style: italic;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 2;
    font-size: 1.5vw;
    border-bottom: 3px solid #000;
    text-shadow: 0.5px 0.5px rgb(0 153 154);
    padding: 10px;
}

.clientCountProgressBar {
    flex: 3;
    color: #fff;
    font-weight: bold;
    font-size: 0.85vw;
    padding: 15px 3%;
    font-style: italic;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 5px 5px 5px 0;
}

.progressbarHeader{
    font-size: 0.9vw;
    margin: 8px;
    text-shadow: 0.5px 0.5px rgb(0, 154, 33);
}

.progressBarOuter {
    margin: 6px 0;
    margin-top: 0.8vw;
    border-radius: 5px;
    padding-top: 1px;
    width: 100%;
    background-color: #979797;
    height: 8px;
}

.progressBarInner {
    position: relative;
    border-radius: 5px;
    background-color: green;
    height: 7px;
}

.fillProgressBar {
    animation: 2.4s ease-out 0s 1 fillProgressBar;
}

.LvmapImg {
    width: 58%;
    margin: 2% auto;
    height: auto;
}

//ANIMATION
@keyframes fillProgressBar {
    0% {
        width: 0;
        background-color: red;
    }

    50% {
        background-color: yellow;
    }

    100% {
        width: 100%;
        background-color: green;
    }
 }



.flex {
    display: flex;
}

//STEPS
.stepsToPerformSolarOrderWrapper {
    border-radius: 5px;
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    margin: 20px 0;
    justify-content: center;
}

.stepsContentWrapper {
    display: flex;
    flex-direction: column;
    text-align: center;       
    width: 16%;
    background-color: #b9b0b0; 
    color: #fff;
    border-radius:  15px;
    box-shadow: 0 0 5px rgb(0 153 154);
    :first-of-type {
        cursor: pointer;
    }
}

.stepsImg {
    width: 42%;
    padding: 8% 6%;
}

.stepsImgWrapper {  
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-bottom: 1px solid #555;
}
 
.stepsHeader {
   font-size: 0.9vw;
    flex: 2;
    color: #191d29;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 8px;
}

.InfoArrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3%;
    padding: 0 2%;
    opacity: 0;
}

.InfoArrowImg {
    width: 100%;
}

.rotateArrow{
    transform: scaleY(-1);
}

.percentage {
    font-size: 0.9vw;
    top: -1.1lh;
    right:0;
    position: absolute;
    text-shadow: 0.5px 0.5px rgb(0, 154, 33);
}

.InfoArrowAnimation {
    animation: 1.5s ease-out 0s 1 InfoArrowAnimation;
    animation-fill-mode: forwards;
}

@keyframes InfoArrowAnimation{
    0% {
     opacity: 0;
    }

    100% {
        
     opacity: 1;
    }
}


@include forSmallDesktop {
    .MapSection {
        padding: 4% $small-display-padding  3% $small-display-padding ; 
    }

    .stepsContentWrapper {   
        width: 18%;
    }

    .progressbarHeader,
    .percentage{
        font-size: 1.1vw;
    }

    .stepsHeader {
        font-size: 1vw;
    }

    .mapSectionHeader {
        font-size: 1.6vw;
    }   
}

@include for-tablet-design {
    .stepsHeader {
        font-size: 1.5vw;
    }

   .MapSection {
        padding: 4% $base-padding-tablet  3% $base-padding-tablet;
    }  

    .clientCount {
        font-size: 2vw;
    }

    .stepsContentWrapper {   
        width: 19%;
    }
    
    .progressbarHeader,
    .percentage{
        font-size: 1.4vw;
    }

 
    .MapSection {
        min-height: 1vh;
    }

    .LvmapImg {
        width: 80%;
    }

    .mapSectionHeader {
        font-size: 1.8vw;
    }
}
  
@include for-mobile-design {
    .clientCount {
        font-size: 3vw;
    }

    .LvmapImg {
        width: 100%;
        margin: 5% auto;
    }

    .stepsImg {
        width: 75%;
    }

    .mapSectionHeader {
        font-size: 3vw;
        margin: 6% 0;
    }

    .stepsHeader {
        font-size: 1.85vw;
        padding: 10px 5px;
    }

    .MapSection {
        padding: 4% $base-padding-mobile   3% $base-padding-mobile ;
    }

    .progressbarHeader,
    .percentage{
        font-size: 2.2vw;
    }
}

 

.imgLazyLoaderStepsImg,
.imgLazyLoaderLvmapImg {
    border: 7px solid #9eddff;
    border-top: 7px solid #3498db;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spinLoader 1s linear infinite;
    margin: 0 auto;
    overflow: hidden;
    box-sizing: border-box;
}

.MapflexItem {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 250px;
}