@import '../../variables';
@import url('https://fonts.googleapis.com/css2?family=Source+Serif+Pro:ital,wght@1,200&display=swap');
 

.MainLogo {
  animation: 1.85s ease-out 0s 1 slideInFromLeft;
  z-index: 2;
  height: 50px;
  width: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 5px;
  cursor: pointer;
}

.CompanyName {
  font-family: 'Serif Pro', serif;
  text-shadow: 0.1px 0.1px #c0c0c0;
  margin-left: 5px;
  letter-spacing: 0.65px;
  font-size: 0.95vw;
  font-style: italic;
  display: flex;
  align-items: center;
}

.navBarLinksWrapper > a {
  font-size: 0.8vw;
  text-decoration: none;
  color: #000000;
  font-weight: bold;
  margin-right: 5px;
  cursor: pointer;

  &:hover {
    color: #0a002e;
  }
}

.navBarLinksWrapper > a::before {
  padding-right: 5px;
    content: ' | ';
}

.navBarLinksWrapper >a:first-of-type::before {
  content: none;
}

.hidden {
  display: none;
}

.MenuShowHideBtn {
  font-weight: bold;
  font-size: 4vw;
  color: #dfffff;
  cursor: pointer;
  display: none;
  padding: 20px 10px 20px 20px;
}

.active {
  color: #004e61 !important;
}

.NavBarWrapper { 
  z-index: 99;
  transition: 0.35s;
  min-height: 95px;
  position: -webkit-sticky;
  position: sticky;
  top: -1px;
  display: flex;
  align-items: center;
  background-color: #dfedff;
  border-bottom: 1px solid #ccc;
}

.caretForMobileMenu {
  display: none;
}

.navBarLinksWrapper {
  padding-left: 50px;
}

.not-pinned {
  min-height: 55px !important;
  transition: 0.75s;
 
  & .MainLogo {
    height: 46px;
    transition: 0.5s;
  }
}

.NavBar {
  padding: 0px $base-padding;
  height: 100%;
  border-radius: 0 0 5px 5px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center; 
  width: 100%;
}


@include forSmallDesktop {
  .NavBar {
       padding: 0 $small-display-padding ;
  }

  .CompanyName,
  .navBarLinksWrapper > a  {
    font-size: 1.1vw;
  }

  .CompanyName{
    font-size: 1.4vw;
  }
}

@include for-tablet-design {
  .NavBar {
       padding: 0 $base-padding-tablet;
  }

  .navBarLinksWrapper > a  {
    font-size: 1.2vw;
  }

  .CompanyName{
    font-size: 1.6vw;
  }
}

@include for-mobile-design {
  .NavBar  {
    padding: 0 $base-padding-mobile ;
    bottom: 0;
  }
 
  .caretForMobileMenu {
    display: inline-block;
    color: #036400;
    padding-right: 10px;
    font-size: 4vw;
  }

  .CompanyName,
  .navBarLinksWrapper > a {
    font-size: 3vw;
    align-items: center;
    display: flex !important;
  }
}

//ANIMATION
@keyframes slideInFromLeft {
  0% {
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
    filter: grayscale(100%);
  }

  40% {
    filter: grayscale(100%);
  }

  100% {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    filter: grayscale(0%);
  }
}

@keyframes slideInFromTop{
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}

.logoWrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
}

//MOBILE
@include for-mobile-design{
  .DesktopLogo {
    display: none;
  }

  .MenuShowHideBtn .logoWrapper {
    flex: 1;
  }

  .logoWrapper{
    justify-content: normal !important;
    padding-left: 10%;
  }

  .MainLogo {
    height: 35px;
    width: 35px;
  }

  .NavBar {
    justify-content: normal !important;
  }

  .NavBar .MenuShowHideBtn {
    color: #fafafa;
    display: flex;
    background-color: #212b35;
    min-height: 70px;
    svg {
      padding-right: 30px;
    }
  }

  .navBarLinksWrapper  {
    flex: 1 !important;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
  
  .NavBar {
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 99;
    animation: 0.65s ease-out 0s 1 slideInFromTop;
    position: fixed;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    display: none;
    padding: 0;
    background-color:#e2e9ff;
    min-height: 100vh
  }


  .NavBar > a {
    text-align: left;
    background-color: #7e7e7e;
    width: 100%;
    margin-bottom: 5px;
    padding: 10px 15px;

    &::after {
      content: '';
    }
  }

  .MenuShowHideBtn {
    display: flex;
    width: 100%;
    align-items: center;
  }
 
  .NavBarWrapper {
    padding-right: 30px;
    text-align: right;
    background-color: #212b35;
    min-height: 75px ;
  }

  .navBarLinksWrapper {
    padding-left: 0;
    text-align: left;

    > a {
      font-size: 3.5vw;
      text-decoration: none;
      color: #000000;
      font-weight: bold;
      margin: 10% 0;
      cursor: pointer;
      display: block;

      &::before {
        content: '';
      }
    }
  }
}