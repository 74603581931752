@import url('https://fonts.googleapis.com/css2?family=Uchen&display=swap');
@import '../../variables';

.viewPostModalWrapper {
    animation: safariIsue 1s ease linear ;
    background-color: #000000cc;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
    height: 100%;
    overflow-x: hidden;
    position: fixed;
    z-index: 999999999 !important;
}

.viewPostModalContent {
    background-color: rgba(226 ,233 ,255 , 1);
    margin: 0 6%;
    position: relative;
    padding: 40px 50px 0 50px;
    min-height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 5px;
    color:#000;
}

.postViewCloseBtn {
    z-index: 99999;
    position: absolute;
    top: 30px;
    right: 40px;
    font-size: 1.3vw;
    background-color: transparent;
    border: none;
    font-weight: bold;
    color:  rgb(138, 0, 0);
    cursor: pointer;
}

 

.PopUpContentWrapper {
    
    & > .homePageProjectWrapper {
        touch-action: none;
        display: flex;
        justify-content: center;
        margin: 0 3% ;
        height: 80vh;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        &:hover {
            & .itemInfo {
                animation: none !important;
                z-index: 99;
                border: none; 
            }
        }
        & > .itemInfo {
            display: none;
            top: 0;
            width: inherit ;
            padding: 0 10px;
            min-width: 20%;
            border: none;
            box-shadow: none;
            height: 1.7lh;
            background-color: rgba(25, 29, 41, 0.815);
            font-size: 0.9vw;
            border-radius: 0 0 19px 46px;;
          
        }
    }
}

.viewPostModalContent  .slick-prev:before, 
.viewPostModalContent .slick-next:before {
    color: #333  !important;
}

@include forSmallDesktop{
    .postViewCloseBtn {
        font-size: 2vw;
    }
}
  
@include for-tablet-design {
    .postViewCloseBtn {
        position: static;
        padding: 3px 15px;
        margin:  1.5% !important;
        font-size: 6vw;
        text-align: center;
        background-color: #ff000042;
    }
 
    .PopUpContentWrapper > .homePageProjectWrapper {
        background-size: cover; 
        height: 80vh;
    }

    .gallerySectionWrapper .slick-slider {
        margin:  1.5% !important;
    }
    .viewPostModalContent {
        padding: 0;
        
        display: flex;
        margin: 0 2%;
    
        .slick-slider  {
            flex: 1;
        }
    }
}
  
  
@include for-mobile-design{}

  @media screen and (orientation:landscape) and (max-width: 992px) {
    .postViewCloseBtn {
        font-size: 5vw;
        height: 100vh;
        padding: 0 15px;
        background-color: #ff000042;
        height: 75vh;
        border-radius: 10px;
    }

    .PopUpContentWrapper {
        & > .homePageProjectWrapper {
            margin: 0;
            height: 85vh;
        }
    }

    .PopUpContentWrapper > .homePageProjectWrapper {
        background-size: cover; 
    }

    .gallerySectionWrapper .slick-slider {
        margin: 0 1.5% !important;
    }

    .viewPostModalContent {
        padding: 0 50px 0 50px;
        display: flex;
        margin: 0;
        flex-direction: row-reverse;
        justify-content: center;
        align-items: center;
        
        .slick-slider  {
            width: 1px;
            flex: 1;
        }
    }
}

@keyframes safariIsue {
  0% {
    opacity: 0.98;
  }

  100% {
    opacity: 0.99999999;
  }
}

.viewPostModalWrapper {
    .slick-prev:before, .slick-next:before {
    font-size: 2vw;
    }

    .slick-prev, .slick-next {
        width: 2vw;
    }

    .slick-prev {
        left: -3vw;
        padding-left: 1vw;
    }

    .slick-next {
        right: -3vw;
        padding-right: 1vw;
    }

    .slick-slider {
        margin: 0 4vw !important;
    }

    .slick-dots li button:before {
        color: #a43c3c !important;
    }

    /* hiding all bullets by default */
    .slick-dots li {
        display: none
    }

    /* only displaying the active bullets and the 2 bullets next to it */
    .slick-dots li.slick-active,
    .slick-dots li.slick-active + li,
    .slick-dots li.slick-active + li + li {
        display: inline-block;
    }

    /* displaying the last three bullets when slick-active class isn't applied to any li before them  */
    .slick-dots li:nth-last-child(1),
    .slick-dots li:nth-last-child(2),
    .slick-dots li:nth-last-child(3) {
        display: inline-block;
    }

    /* hiding the last three bullets if slick-active exist before them */
    .slick-dots li.slick-active ~ li:nth-last-child(1),
    .slick-dots li.slick-active ~ li:nth-last-child(2),
    .slick-dots li.slick-active ~ li:nth-last-child(3) {
        display: none;
    }

    /* specific conditions to always display the last three bullets */
    .slick-dots li.slick-active + li + li:nth-last-child(3),
    .slick-dots li.slick-active + li + li:nth-last-child(2),
    .slick-dots li.slick-active + li + li:nth-last-child(1),
    .slick-dots li.slick-active + li:nth-last-child(3),
    .slick-dots li.slick-active + li:nth-last-child(2),
    .slick-dots li.slick-active + li:nth-last-child(1){
        display: inline-block;
    }
}

