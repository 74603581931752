@import '../../variables';

.gallerySectionWrapper {
    background-color: rgb(25, 29, 41);
    color: #fff;
    min-height: 70vh;
    border-radius: 5px;
    padding: 3% $base-padding 20px $base-padding;

    * {
        z-index: 0 ;
    }
}

.slick-dots li.slick-active button:before,
.slick-dots li button:before   { 
    color: #fff !important;
}

.slick-dots {
    position:static !important;
}

.galleryHeader {
    font-size: 1.3vw;
    color: #cccccc;
    text-shadow: 0.5px 0.5px rgb(0 153 154);
    text-align: center;
    letter-spacing: 0.5px;
    margin: 0 0 3% 0;
}

@include forSmallDesktop {
    .gallerySectionWrapper {
        padding:  3%  $small-display-padding  20px $small-display-padding ;
    }

    .galleryHeader {
        font-size: 1.6vw;
    }
}

@include for-tablet-design {
    .gallerySectionWrapper {
        padding:  3%  $base-padding-tablet  20px $base-padding-tablet  ;
        min-height: 1vh;
    }

    .galleryHeader {
        font-size: 1.8vw;
    }
  }
  
@include for-mobile-design {
    .gallerySectionWrapper {
        padding:  3%  $base-padding-mobile   20px $base-padding-mobile   ;
        min-height: 1vh;
    }
    
    .gallerySectionWrapper .slick-slider {
        margin: 0 10%;
    }

    .galleryHeader {
        font-size: 3vw;
    }
}

.gallerySectionWrapper {
    *:focus {
        outline: none;
    }
    
    .slick-prev:before, .slick-next:before {
    font-size: 2vw;
    }

    .slick-prev, .slick-next {
        width: 4vw;
    }

    .slick-prev {
        left: -3vw;
        padding: 0 1vw;
    }

    .slick-next {
        right: -3vw;
        padding: 0 1vw;
    }

    .slick-slider {
        margin: 0 3vw !important;
    }
}