@import '../../variables';

.worksWeDoWrapper {
  animation: 0.65s ease-out 0s 1 worksWeDoTransit;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 29.5%;
    margin: 3% 3% 3% 0;
    background-color: rgb(25, 29, 41);
    border-radius: 5%;
    min-height: 21vh;
    font-size: 0.74vw;
    color: #fff;
    text-align: center;
    margin-bottom: 30px;
    box-shadow: 0 0 15px rgb(0 153 154);
}

.worksWeDoWrapperLogo {
  transform: rotate(2deg);
  width: 76%;
  margin: 0 auto;
  padding: 12%;
  height: auto;
  border-bottom: 1px solid transparent ;
}

.worksWeDoLogoWrapper {
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.worksWeDoHeader {
  flex: 1;
  padding: 5px;
  margin: 0;
  border-top: 1px solid #333 ;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 -6px 10px -6px rgb(0 153 154);
  text-transform: uppercase;
}

@keyframes worksWeDoTransit{
  0% {
    transform: translateX(-200%);
  }

  100% {
    transform: translateX(0);
  }
}

@include forSmallDesktop{
  .worksWeDoWrapper{
    width: 42.5%;
  }

  .worksWeDoHeader {
    font-size: 1.2vw;
      padding: 15px 5px;
  }
}

@include for-tablet-design {
  .worksWeDoWrapper{
    width: 28.5%;
  }

  .worksWeDoHeader {
    font-size: 1.3vw;
  }
}

@include for-mobile-design{
  .worksWeDoWrapper{
    margin-left: 2% !important;
    margin-right: 2% !important;
  }

  .worksWeDoHeader {
    padding: 10px 5px;
    font-size: 1.9vw;
  }
}

.imgLazyLoaderWorksWeDo {
  border: 7px solid #9eddff;
  border-top: 7px solid #3498db;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spinLoader 1s linear infinite;
  margin: 0 auto;
  margin: 40px auto;
}