@import '../../variables';

.contactInfo {
  padding:  3px $base-padding;
  display: flex;
  height: 32px;
  background-color: rgb(35, 43, 54 , 0.87);
  color: #fff;
  align-items: center;
  font-size: 0.8vw;
  justify-content: right;
  padding-right: 18%;

  div {
    margin: 0 12px ;
    border-radius: 5px;
    padding: 3px;
  }
}

.languageBtn {
  background-color: rgb(67, 81, 104);
  text-align: left;
  cursor: pointer;
 
  &:hover {
    background-color: rgb(78, 95, 121);
  }
}

@include forSmallDesktop {
  .contactInfo {
    padding: 0 $small-display-padding ;
    font-size: 1.1vw;
  }
}

@include for-tablet-design {
  .contactInfo {
    padding: 0 $base-padding-tablet;
    font-size: 1.6vw;
  }

  .contactInfo > div {
    margin: 0 9px;
    padding: 5px 12px;
  }
}

@include for-mobile-design {
  .contactInfo > div {
    margin: 0 9px;
    padding: 3px 8px;
  }

  .contactInfo {
    justify-content: center;
    font-size: 3vw;
    padding: 7.5px $base-padding-mobile ;
    background-color: #2c3946;
  }
}

.cookies, 
.displayNone {
  border-radius: 5px 5px 0 0 !important;
  padding: 7px 0 !important;
  margin: 0 !important;
  background-color: #2c3946;
  font-weight: bold;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999999999;
  text-transform: uppercase;
}

.cookiesAgreeBtn {
  margin: 0 5px;
  background-color: green;
  border: none;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  color: #fff;
  font-weight: bold;
}

.displayNone {
  animation: cookiesDisaper 1s ease-out forwards ;
 }

 .displayNoneNoAnimation {
  display: none;
 }

@keyframes cookiesDisaper {
  0% {
    opacity: 1;
    display: block;
  }

  100% {
    opacity: 0;
    display: none;
  }
}