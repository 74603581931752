//MAX WIDTH FOR MOBILE
$width-forSmallDesktop: 1280px;
$width-for-tablet-design: 992px;
$width-for-mobile-design: 767px;
$base-padding: 15%;
$small-display-padding: 8%;
$base-padding-tablet: 5%;
$base-padding-mobile: 2.5%;

//DESIGN FOR MOBILE
@mixin forSmallDesktop() {
  @media only screen and (max-width: $width-forSmallDesktop) {
    @content;
  }
}
  
@mixin for-tablet-design() {
  @media only screen and (max-width: $width-for-tablet-design) {
    @content;
  }
}
  
@mixin for-mobile-design() {
  @media only screen and (max-width: $width-for-mobile-design) {
    @content;
  }
}